import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import PublicPage from './components/PublicPage';
import useAuth from './hooks/useAuth';

function App() {
  const { isAuthenticated, setIsAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/login"
            element={
              isAuthenticated ?
                <Navigate to="/dashboard" /> :
                <Login setIsAuthenticated={setIsAuthenticated} />
            }
          />
          <Route
            path="/register"
            element={
              isAuthenticated ?
                <Navigate to="/dashboard" /> :
                <Register setIsAuthenticated={setIsAuthenticated} />
            }
          />
          <Route
            path="/dashboard"
            element={
              isAuthenticated ?
                <Dashboard setIsAuthenticated={setIsAuthenticated} /> :
                <Navigate to="/login" />
            }
          />
          {/* PublicPage akan menampilkan foto profil jika tersedia */}
          <Route path="/:urlPath" element={<PublicPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;