import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, Link as LinkIcon, User, Settings, LogOut, Save, ExternalLink, Upload, X, Menu, ChevronDown, Home, Bell, CreditCard, Sun, Moon } from 'lucide-react';

const Dashboard = ({ setIsAuthenticated }) => {
  const [userId, setUserId] = useState('');
  const [pageData, setPageData] = useState({
    urlPath: '',
    pageContent: {
      title: '',
      description: '',
      backgroundColor: '#ffffff',
      textColor: '#000000',
      links: [],
      profileImageUrl: ''
    }
  });
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('Your session has expired. Please log in again.');
      setIsAuthenticated(false);
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://api.devilteam.org/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const userData = await response.json();
      setUserId(userData._id);
      setPageData({
        urlPath: userData.urlPath || '',
        pageContent: userData.pageContent || {
          title: '',
          description: '',
          backgroundColor: '#ffffff',
          textColor: '#000000',
          links: [],
          profileImageUrl: userData.profileImageUrl || ''
        }
      });
    } catch (error) {
      setMessage(`Failed to fetch user data: ${error.message}`);
    }
  }, [setIsAuthenticated, navigate]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPageData(prevData => ({
      ...prevData,
      pageContent: {
        ...prevData.pageContent,
        [name]: value
      }
    }));
  };

  const handleUrlPathChange = (e) => {
    setPageData(prevData => ({
      ...prevData,
      urlPath: e.target.value
    }));
  };

  const addLink = () => {
    setPageData(prevData => ({
      ...prevData,
      pageContent: {
        ...prevData.pageContent,
        links: [...prevData.pageContent.links, { title: '', url: '' }]
      }
    }));
  };

  const updateLink = (index, field, value) => {
    setPageData(prevData => {
      const newLinks = [...prevData.pageContent.links];
      newLinks[index] = { ...newLinks[index], [field]: value };
      return {
        ...prevData,
        pageContent: {
          ...prevData.pageContent,
          links: newLinks
        }
      };
    });
  };

  const removeLink = (index) => {
    setPageData(prevData => ({
      ...prevData,
      pageContent: {
        ...prevData.pageContent,
        links: prevData.pageContent.links.filter((_, i) => i !== index)
      }
    }));
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const uploadProfileImage = async () => {
    if (!profileImage) return null;
  
    setIsUploading(true);
    const formData = new FormData();
    formData.append('profileImage', profileImage);
  
    try {
      const response = await fetch('https://api.devilteam.org/api/images/upload-profile-image', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to upload image');
      }
  
      const data = await response.json();
      setIsUploading(false);
      return data.imageUrl; 
    } catch (error) {
      setIsUploading(false);
      throw error;
    }
  };

  const savePageContent = async () => {
    try {
      const profileImageUrl = await uploadProfileImage();

      const dataToSend = {
        userId,
        urlPath: pageData.urlPath,
        pageContent: {
          ...pageData.pageContent,
          profileImageUrl: profileImageUrl || pageData.pageContent.profileImageUrl
        }
      };

      const response = await fetch('https://api.devilteam.org/api/auth/update-page-content', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        throw new Error('Failed to save content');
      }

      setMessage('Page content saved successfully!');
      const responseData = await response.json();
      setPageData(prevData => ({
        ...prevData,
        ...responseData
      }));
    } catch (error) {
      setMessage(`An error occurred while saving page content: ${error.message}`);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  const visitPublicPage = () => {
    window.open(`https://devilteam.org/${pageData.urlPath}`, '_blank');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      {/* Header */}
      <header className={`fixed top-0 left-0 right-0 z-50 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={toggleSidebar} className="mr-4 md:hidden">
              <Menu className="h-6 w-6" />
            </button>
            <h1 className={`text-2xl font-bold ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>WebCraft</h1>
          </div>
          <div className="flex items-center space-x-4">
            <button onClick={toggleDarkMode} className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
            <button 
              onClick={handleLogout}
              className="flex items-center px-4 py-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200 transition duration-300"
            >
              <LogOut className="h-5 w-5 mr-2" />
              <span className="hidden md:inline">Logout</span>
            </button>
          </div>
        </div>
      </header>

      {/* Sidebar */}
      <aside className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
        <div className="h-full px-3 py-4 overflow-y-auto">
          <ul className="space-y-2">
            {[
              { icon: Home, text: 'Dashboard', id: 'dashboard' },
              { icon: LinkIcon, text: 'Edit Page', id: 'edit' },
              { icon: User, text: 'Profile', id: 'profile' },
              { icon: Bell, text: 'Notifications', id: 'notifications' },
              { icon: CreditCard, text: 'Billing', id: 'billing' },
              { icon: Settings, text: 'Settings', id: 'settings' },
            ].map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => setActiveTab(item.id)}
                  className={`flex items-center w-full p-2 rounded-lg ${
                    activeTab === item.id 
                      ? (isDarkMode ? 'bg-sky-700 text-white' : 'bg-sky-100 text-sky-600') 
                      : (isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100')
                  } transition duration-300`}
                >
                  <item.icon className="h-5 w-5 mr-3" />
                  <span className="flex-1 whitespace-nowrap">{item.text}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </aside>

      {/* Main Content */}
      <main className={`pt-16 md:ml-64 min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
        <div className="container mx-auto px-4 py-8">
          {message && (
            <div className={`${isDarkMode ? 'bg-sky-900 border-sky-700' : 'bg-sky-100 border-sky-500'} border-l-4 text-sky-700 p-4 mb-6 rounded-lg shadow-md`} role="alert">
              <p>{message}</p>
            </div>
          )}

          {/* URL Path Section */}
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-6 mb-8`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>Your URL Path</h2>
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
              <input
                type="text"
                placeholder="Choose your URL path"
                value={pageData.urlPath}
                onChange={handleUrlPathChange}
                className={`w-full md:flex-1 px-4 py-3 border ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-sky-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 transition duration-300`}
              />
              <button className={`w-full md:w-auto px-6 py-3 ${isDarkMode ? 'bg-sky-700 hover:bg-sky-600' : 'bg-sky-500 hover:bg-sky-600'} text-white rounded-lg transition duration-300 shadow-md hover:shadow-lg`}>
                Save Path
              </button>
            </div>
            {pageData.urlPath && (
              <p className={`mt-4 text-sm ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>
                Your bio link: <span className="font-medium">https://web.devilteam.org/{pageData.urlPath}</span>
              </p>
            )}
          </div>

          {/* Page Content Section */}
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-6 mb-8`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>Page Content</h2>
            <div className="space-y-6">
              <div>
                <label className={`block text-sm font-medium ${isDarkMode ? 'text-sky-400' : 'text-sky-700'} mb-2`}>Page Title</label>
                <input
                  type="text"
                  name="title"
                  value={pageData.pageContent.title}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-3 border ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-sky-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 transition duration-300`}
                />
              </div>
              <div>
                <label className={`block text-sm font-medium ${isDarkMode ? 'text-sky-400' : 'text-sky-700'} mb-2`}>Description</label>
                <textarea
                  name="description"
                  value={pageData.pageContent.description}
                  onChange={handleInputChange}
                  rows="4"
                  className={`w-full px-4 py-3 border ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-sky-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 transition duration-300 resize-none`}
                ></textarea>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0">
                <div className="w-full md:w-1/2">
                  <label className={`block text-sm font-medium ${isDarkMode ? 'text-sky-400' : 'text-sky-700'} mb-2`}>Background Color</label>
                  <div className="flex items-center">
                    <input
                      type="color"
                      name="backgroundColor"
                      value={pageData.pageContent.backgroundColor}
                      onChange={handleInputChange}
                      className="w-12 h-12 rounded-lg border cursor-pointer"
                    />
                    <span className={`ml-3 text-sm ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>{pageData.pageContent.backgroundColor}</span>
                    </div>
                </div>
                <div className="w-full md:w-1/2">
                  <label className={`block text-sm font-medium ${isDarkMode ? 'text-sky-400' : 'text-sky-700'} mb-2`}>Text Color</label>
                  <div className="flex items-center">
                    <input
                      type="color"
                      name="textColor"
                      value={pageData.pageContent.textColor}
                      onChange={handleInputChange}
                      className="w-12 h-12 rounded-lg border cursor-pointer"
                    />
                    <span className={`ml-3 text-sm ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>{pageData.pageContent.textColor}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Edit Profile Section */}
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-6 mb-8`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>Edit Profile</h2>
            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
              {pageData.pageContent.profileImageUrl && (
                <img 
                  src={pageData.pageContent.profileImageUrl} 
                  alt="Current Profile" 
                  className="w-32 h-32 object-cover rounded-full border-4 border-sky-300 shadow-lg"
                />
              )}
              <div>
                <input 
                  type="file" 
                  onChange={handleProfileImageChange}
                  className="hidden"
                  id="profile-image-input"
                  accept="image/*"
                />
                <label 
                  htmlFor="profile-image-input" 
                  className={`cursor-pointer inline-flex items-center px-6 py-3 border ${isDarkMode ? 'border-sky-700 text-sky-400 hover:bg-sky-900' : 'border-sky-300 text-sky-600 hover:bg-sky-50'} rounded-lg shadow-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 transition duration-300`}
                >
                  {isUploading ? (
                    <Upload className="h-5 w-5 mr-2 animate-spin" />
                  ) : (
                    <Upload className="h-5 w-5 mr-2" />
                  )}
                  {isUploading ? 'Uploading...' : 'Choose Profile Photo'}
                </label>
              </div>
            </div>
          </div>

          {/* Links Section */}
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-6 mb-8`}>
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-sky-400' : 'text-sky-600'}`}>Your Links</h2>
            {pageData.pageContent.links.length === 0 ? (
              <p className={`${isDarkMode ? 'text-sky-400' : 'text-sky-600'} mb-4`}>No links added yet. Start by adding a new link below!</p>
            ) : (
              <div className="space-y-4 mb-6">
                {pageData.pageContent.links.map((link, index) => (
                  <div key={index} className={`flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2 ${isDarkMode ? 'bg-gray-700' : 'bg-sky-50'} p-4 rounded-lg`}>
                    <input
                      type="text"
                      value={link.title}
                      onChange={(e) => updateLink(index, 'title', e.target.value)}
                      className={`w-full md:w-1/3 px-4 py-3 border ${isDarkMode ? 'bg-gray-600 border-gray-500 text-white' : 'bg-white border-sky-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 transition duration-300`}
                      placeholder="Link Title"
                    />
                    <input
                      type="url"
                      value={link.url}
                      onChange={(e) => updateLink(index, 'url', e.target.value)}
                      className={`w-full md:w-1/2 px-4 py-3 border ${isDarkMode ? 'bg-gray-600 border-gray-500 text-white' : 'bg-white border-sky-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 transition duration-300`}
                      placeholder="URL"
                    />
                    <button 
                      onClick={() => removeLink(index)}
                      className={`w-full md:w-auto px-4 py-3 ${isDarkMode ? 'bg-red-900 text-red-300 hover:bg-red-800' : 'bg-red-100 text-red-600 hover:bg-red-200'} rounded-lg transition duration-300 flex items-center justify-center`}
                    >
                      <X className="h-5 w-5 mr-2" />
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
            <button 
              onClick={addLink}
              className={`px-6 py-3 ${isDarkMode ? 'bg-sky-700 hover:bg-sky-600' : 'bg-sky-500 hover:bg-sky-600'} text-white rounded-lg transition duration-300 flex items-center shadow-md hover:shadow-lg`}
            >
              <PlusCircle className="h-5 w-5 mr-2" />
              Add Link
            </button>
          </div>

          <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
            <button 
              onClick={savePageContent}
              className={`w-full md:w-auto px-8 py-4 ${isDarkMode ? 'bg-green-700 hover:bg-green-600' : 'bg-green-500 hover:bg-green-600'} text-white rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg`}
            >
              <Save className="h-5 w-5 mr-2" />
              Save Changes
            </button>
            <button 
              onClick={visitPublicPage}
              className={`w-full md:w-auto px-8 py-4 ${isDarkMode ? 'bg-sky-700 hover:bg-sky-600' : 'bg-sky-500 hover:bg-sky-600'} text-white rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg`}
            >
              <ExternalLink className="h-5 w-5 mr-2" />
              Visit Public Page
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
