import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Code, Layers, Zap, ArrowRight, Menu, X } from 'lucide-react';

const HomePage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="min-h-screen bg-black text-white font-sans">
      <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-black bg-opacity-90 backdrop-blur-md py-2' : 'bg-transparent py-4'}`}>
        <div className="container mx-auto px-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Layout className="h-8 w-8 text-blue-400" />
              <span className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">WebCraft</span>
            </div>
            <nav className="hidden md:block">
              <ul className="flex space-x-8">
                {['Features', 'Templates', 'Pricing', 'Blog'].map((item) => (
                  <li key={item}>
                    <a href="#" className="text-gray-300 hover:text-blue-400 transition duration-300 relative group">
                      {item}
                      <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-blue-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="flex items-center space-x-4">
              <button 
                onClick={handleNavigateToDashboard}
                className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-2 rounded-full hover:from-blue-600 hover:to-blue-800 transition duration-300 shadow-lg hover:shadow-blue-500/50"
              >
                Get Started
              </button>
              <button className="md:hidden text-blue-400" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-40 flex items-center justify-center md:hidden">
          <nav>
            <ul className="space-y-6 text-center">
              {['Features', 'Templates', 'Pricing', 'Blog'].map((item) => (
                <li key={item}>
                  <a href="#" className="text-2xl text-gray-300 hover:text-blue-400 transition duration-300">{item}</a>
                </li>
              ))}
              <li>
                <button 
                  onClick={handleNavigateToDashboard}
                  className="text-2xl text-blue-400 hover:text-blue-300 transition duration-300"
                >
                  Dashboard
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}

      <main>
        <section className="pt-40 pb-32 px-6 bg-gradient-to-b from-black via-gray-900 to-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80')] opacity-10 bg-cover bg-center"></div>
          <div className="max-w-4xl mx-auto text-center relative z-10">
            <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">
              Craft Digital Excellence
            </h1>
            <p className="text-xl md:text-2xl mb-10 text-gray-300">
              Elevate your online presence with unparalleled sophistication and style.
            </p>
            <button 
              onClick={handleNavigateToDashboard}
              className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-blue-800 transition duration-300 shadow-lg hover:shadow-blue-500/50 transform hover:-translate-y-1"
            >
              Create Web Now
            </button>

          </div>
        </section>

        <section className="py-24 px-6 bg-gray-900 relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-900/20 to-transparent opacity-70"></div>
          <div className="max-w-6xl mx-auto relative z-10">
            <h2 className="text-4xl md:text-5xl font-bold text-center mb-16 bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">
              The Essence of WebCraft
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {[
                { icon: <Code className="h-16 w-16 text-blue-400" />, title: "React-Powered Brilliance", description: "Harness the full potential of React for websites that redefine modern web standards." },
                { icon: <Layers className="h-16 w-16 text-blue-400" />, title: "Intuitive Mastery", description: "Shape your digital masterpiece with our state-of-the-art drag & drop interface." },
                { icon: <Zap className="h-16 w-16 text-blue-400" />, title: "Unrivaled Performance", description: "Experience lightning-fast load times and smooth interactions that captivate your audience." },
              ].map((feature, index) => (
                <div key={index} className="bg-black bg-opacity-50 p-8 rounded-2xl shadow-2xl hover:shadow-blue-500/20 transition duration-300 transform hover:-translate-y-2">
                  <div className="flex justify-center mb-6">{feature.icon}</div>
                  <h3 className="text-2xl font-semibold mb-4 text-blue-400">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-24 px-6 bg-black relative overflow-hidden">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80')] opacity-5 bg-cover bg-center"></div>
          <div className="max-w-4xl mx-auto text-center relative z-10">
            <h2 className="text-4xl md:text-5xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">
              Ready to Redefine Your Digital Presence?
            </h2>
            <a href="#" className="inline-flex items-center bg-gradient-to-r from-blue-500 to-blue-700 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-blue-600 hover:to-blue-800 transition duration-300 shadow-lg hover:shadow-blue-500/50 transform hover:-translate-y-1">
              Begin Your Transformation
              <ArrowRight className="ml-2 h-5 w-5" />
            </a>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 py-16 px-6 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-900/10 to-transparent opacity-70"></div>
        <div className="max-w-6xl mx-auto relative z-10">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-12">
            <div>
              <h3 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">WebCraft</h3>
              <p className="text-gray-400">Redefining web design, one masterpiece at a time.</p>
            </div>
            {['Product', 'Company', 'Resources'].map((category) => (
              <div key={category}>
                <h3 className="text-xl font-semibold mb-4 text-blue-400">{category}</h3>
                <ul className="space-y-2">
                  {['Premium Features', 'Success Stories', 'Design Insights'].map((link) => (
                    <li key={link}>
                      <a href="#" className="text-gray-400 hover:text-blue-300 transition duration-300">{link}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="border-t border-gray-800 mt-12 pt-8 text-sm text-gray-400 text-center">
            <p>&copy; 2024 WebCraft. All rights reserved.</p>
            <p className="mt-2">Crafting digital excellence with passion and precision.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
