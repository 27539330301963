import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ExternalLink, GithubIcon, TwitterIcon, InstagramIcon, LinkedinIcon, Loader2, Mail } from 'lucide-react';

const PublicPage = () => {
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { urlPath } = useParams();

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await fetch(`https://api.devilteam.org/api/pages/${urlPath}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Tambahkan base URL backend ke imageUrl jika perlu
        if (data.profileImageUrl && !data.profileImageUrl.startsWith('http')) {
          data.profileImageUrl = `https://api.devilteam.org${data.profileImageUrl}`;
        }
        setPageContent(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching page content:', err);
        setError(`Failed to load page. Error: ${err.message}`);
        setLoading(false);
      }
    };

 

    fetchPageContent();
  }, [urlPath]);

  const getIconForLink = (url) => {
    if (url.includes('github')) return <GithubIcon className="w-5 h-5" />;
    if (url.includes('twitter')) return <TwitterIcon className="w-5 h-5" />;
    if (url.includes('instagram')) return <InstagramIcon className="w-5 h-5" />;
    if (url.includes('linkedin')) return <LinkedinIcon className="w-5 h-5" />;
    if (url.includes('mailto:')) return <Mail className="w-5 h-5" />;
    return <ExternalLink className="w-5 h-5" />;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-teal-100 to-cyan-100">
        <Loader2 className="w-12 h-12 text-teal-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-rose-100 to-pink-100 text-rose-700 text-xl font-bold p-4 text-center">
        {error}
      </div>
    );
  }

  if (!pageContent) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-gray-100 to-gray-200 text-gray-700 text-xl font-bold">
        No content found for this page.
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gradient-to-r from-teal-50 to-cyan-50">
      <AnimatePresence>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md bg-white rounded-3xl shadow-xl p-8 border border-teal-100"
        >
          {pageContent.profileImageUrl && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
              className="relative w-32 h-32 mx-auto mb-6"
            >
            <img 
  src={pageContent.profileImageUrl}
  alt="Profile"
  className="w-full h-full rounded-full object-cover border-4 border-teal-200 shadow-lg"
/>
              <div className="absolute inset-0 rounded-full shadow-inner"></div>
            </motion.div>
          )}
          <motion.h1 
            className="text-4xl font-extrabold text-center mb-4 text-teal-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            {pageContent.title}
          </motion.h1>
          <motion.p 
            className="text-lg text-center mb-8 text-teal-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {pageContent.description}
          </motion.p>
          <div className="space-y-4">
            {pageContent.links && pageContent.links.map((link, index) => (
              <motion.a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between w-full py-3 px-6 bg-gradient-to-r from-teal-500 to-cyan-500 hover:from-teal-600 hover:to-cyan-600 text-white rounded-lg transition duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 * index }}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <span className="font-medium">{link.title}</span>
                {getIconForLink(link.url)}
              </motion.a>
            ))}
          </div>
        </motion.div>
      </AnimatePresence>
      <motion.footer 
        className="mt-12 text-sm text-teal-600"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        Created By Devilteam Bio
      </motion.footer>
    </div>
  );
};

export default PublicPage;
